export default {
  address: '台南安平區健康路三段VS.國平路',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.083837190087!2d120.16795021496706!3d22.983944384972727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjLCsDU5JzAyLjIiTiAxMjDCsDEwJzEyLjUiRQ!5e0!3m2!1szh-TW!2stw!4v1634279092262!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/ZGYjcUMxGgF2gGnv8',
  phone: '06-299-2788',
  fbLink: 'https://www.facebook.com/VVVVV.HAI.TANG/',
  fbMessage: 'http://m.me/VVVVV.HAI.TANG/',
  caseName: '聯上海棠',
  indigatorLength: 10,

  houseInfos: [
    ['地址', '台南安平區健康路三段VS.國平路'],
    ['電話', '06-2992788'],

    ['投資興建', '聯上實業股份有限公司'],
    ['營造公司', '張文明建築師事務所'],
    ['基地面積', '1500坪'],
    ['樓層規劃', '地上29層，地下5層'],
    ['棟戶規劃', '5棟，417戶住家，2戶店面'],
    ['車位規劃', '平面式314個、機械式139個'],
    ['建造執照', '104南工造字第2933-01號等1個'],
  ],

  gtmCode: ['K2JQPRL'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
} 